import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { IcoStar, Select } from '../../../components'
import './npscliente.scss'
import Filter from './filter/filter'
import Grafico from './grafico'
import { carregarGrafico, filterHandle } from './redux/npsclienteActions'
import { setInfoEmpresa, setProjeto, setInfoGrupo1 } from '../../../layout/redux/layoutActions'

export default () => {
  const dispatch = useDispatch()
  const { filters, empresas, empresa, grupos1, grupo1 } = useSelector(state=> state.layoutState)
  const { projeto, totalAvaliacao, projetos, filtersProvisorio } = useSelector(state=> state.npsclienteState)



  const grupoNomes = grupos1.map(objeto => objeto.name);

  useEffect(()=> {
    if (projeto.id) {
      dispatch(filterHandle({projetoID: projeto.id, empresaId: empresa.empresaId, params: filters, filtersProvisorio, typeSelect: filters.typeSelect, grupo: grupo1.name}))
    }
  }, [projeto, empresa, grupo1])
  
  const handleFormFilter = (e) => {
    dispatch(setProjeto(e.value))
  }

  const handleFormFilterEmpresa = (e) => {
    dispatch(setInfoEmpresa(e.value))
  }
  
  const handleGrupo = (e) => {
    dispatch(setInfoGrupo1(e.value))
  }


  return (
    <div className='box-npscliente'>
      <div className='box-npscliente-topinfo'>
        <div>
          <div className='box-npscliente-topinfo-select'>
            <Select 
              name='projeto'
              label='Projeto:'
              options={projetos}
              selected={projeto}
              action={(e)=> handleFormFilter(e)}
              selectedItem={false}
              optionLabel='nomeProjeto'
              optionValue='id'
            />
          </div>
          <div className='box-csatcliente-topinfo-select'>
            <Select 
              name='empresa'
              label='Empresa analisada:'
              options={empresas}
              selected={empresa}
              action={(e)=> handleFormFilterEmpresa(e)}
              selectedItem={false}
              optionLabel='descricaoEmpresa'
              optionValue='empresaId'
            />
          </div>
          <div className='box-rankingcsat-topinfo-select'>
            <Select 
              name='grupo'
              label='Grupo analisado:'
              options={grupos1}
              selected={grupo1}
              action={(e)=> handleGrupo(e)}
              selectedItem={false}
            />
          </div>
        </div>
        <div className='box-npscliente-topinfo-avaliacao'>
            <label>Número de avaliações:</label>
            <IcoStar />
            <strong>{totalAvaliacao?totalAvaliacao:0}</strong>
        </div>
      </div>
      <Filter />
      <Grafico />
    </div>
  )
}
