import React, { useEffect, useState } from 'react'
import Header from './header'
import { Alert, Loading } from '../components'
import SidebarLeft from './sidebarLeft'
import { useDispatch, useSelector } from 'react-redux'
import { listarProjetos, loged, setInfoGrupo, setInfoProjeto } from './redux/layoutActions'
// import { menuItens } from './menuItens'

export default ({ children }) => { 
  const dispatch = useDispatch()
  const { 
    load, 
    logIn, 
    projeto, 
    projetos, 
    usuario,
    grupo,
    grupos,
    grupo1,
    grupos1,
  } = useSelector(state=> state.layoutState)

  const [heigthHeader, setHeigthHeader] = useState(0)
  useEffect(() => {
    setHeigthHeader(document.getElementById('box-header')?.offsetHeight)
  }, [logIn])
  const heightApp = { height: `calc(100vh - ${heigthHeader}px)` }
  
  useEffect(()=> {
    dispatch(loged())
  },[dispatch])

  useEffect(()=> {
    dispatch([
      listarProjetos(usuario.email)
    ])
  },[])
  
  // useEffect(()=> {
  //   const hasProject = !projeto
  //   const hasGroup = !grupo
  //   console.log(projeto, projetos, 'projeto, projetosprojeto, projetosprojeto, projetos', hasProject);
  //   if (!hasProject && projetos.length) {
  //     dispatch(setInfoProjeto(projetos[0]))
  //   }
  //   if (!hasGroup && grupos.length) {
  //     dispatch(setInfoGrupo(grupos[0]))
  //   }
  // },[projetos, grupos])

  if (!logIn) {
    return <Loading />
  }
  return (
    <>
      {load? <Loading />:null}
      <Alert />
      <div id='box-app'>
        <SidebarLeft />
        <div id='box-app-container'>
          <Header />
          <section id='box-container' style={heightApp}>
            <div>
              {children}
            </div>
          </section>
        </div>
      </div>
    </>
  )
}
