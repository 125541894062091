import React from 'react'
import { IcoCalendar, IcoCicle } from '../../components'

const user = {
  emailUsuario: '',
  nomeEmpresa: '',
  nomeUsuario: '',
  id: '',
  usuarioID: ''
}
const userINSV = localStorage.getItem('userINSV')
const userInfo = JSON.parse(userINSV)

const filteredINSV = localStorage.getItem('filtered')
const initialStateFilters = {
  ciclo: {},
  compararCiclos: {id: 'ativar', name: 'Ativar'},
  dataInicial: null,
  dataFinal: null,
  typeSelect: {
    id: 'Ciclo',
    text: 'Filtrar por Ciclo',
    label: <IcoCicle />
  }, 
  categoria: {},
  empresa: {},
}
const filteredInfo = filteredINSV? {...initialStateFilters, ...JSON.parse(filteredINSV)}:initialStateFilters

export const nomesMeses = [
  {id: 1, name: 'Janeiro'},
  {id: 2, name: 'Fevereiro'},
  {id: 3, name: 'Março'},
  {id: 4, name: 'Abril'},
  {id: 5, name: 'Maio'},
  {id: 6, name: 'Junho'},
  {id: 7, name: 'Julho'},
  {id: 8, name: 'Agosto'},
  {id: 9, name: 'Setembro'},
  {id: 10, name: 'Outubro'},
  {id: 11, name:  'Novembro'},
  {id: 12, name: 'Dezembro'}
]
const initialState = {
  logIn: false,
  load: false,
  sidebarRight: false,
  sidebarLeft: false,
  statusModal: '',
  titlePage: '',
  usuario: userInfo?userInfo:user,
  projetos: [],
  projeto: {},
  cliente: {},
  grupos: [],
  grupo: {},
  empresas: [],
  empresa: {},
  categorias: [],
  categoria: {},
  ciclos: [],
  ciclo: {},
  
  filters: filteredInfo,
  filterActive: false,
  filtered: {},

  grupos1: [],
  grupo1: {},
  
  listas: {
    listCiclos:[
      {id: 1, name: 'Janeiro'},
      {id: 2, name: 'Fevereiro'}
    ],
    nomesMeses: nomesMeses,
   typeSearch:[
    {
      id: 'Ciclo',
      text: 'Filtrar por Ciclo',
      label: <IcoCicle />
    },
    {
      id: 'Data',
      text: 'Filtrar por Data',
      label: <IcoCalendar />
    }
  ]
  },
  erro: {},
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'LOADING':
      return { ...state, load: payload }
    case 'LOG_IN':
      return { ...state, logIn: payload }
    case 'USUARIO_LOGADO_CARREGAR':
      return { ...state, usuario: payload }
    case 'LIST_PROJETOS':
      return { ...state, projetos: payload}
    case 'INFO_PROJETO':
      return { ...state, projeto: payload }
    case 'INFO_CLIENTE':
      return { ...state, cliente: payload }
    case 'LIST_GRUPOS':
      return { ...state, grupos: payload }
    case 'INFO_GRUPO':
      return { ...state, grupo: payload }

    case 'LIST_GRUPOS1':
      return { ...state, grupos1: payload }
    case 'INFO_GRUPO1':
      return { ...state, grupo1: payload }




    case 'LIST_EMPRESAS':
      return { ...state, empresas: payload }
    case 'INFO_EMPRESA':
      return { ...state, empresa: payload }
    case 'LIST_CATEGORIAS':
      return { ...state, categorias: payload }
    case 'INFO_CATEGORIA':
      return { ...state, categoria: payload }
    case 'LIST_CICLOS':
      return { ...state, ciclos: payload }
    case 'INFO_CICLO':
      return { ...state, ciclo: payload }
      
    case 'SIDEBAR_LEFT':
      return { ...state, sidebarLeft: payload }
    case 'SIDEBAR_RIGHT':
      return { ...state, sidebarRight: payload }
    case 'MODAL_OPEN':
      return { ...state, statusModal: payload }
    case 'TITLE_PAGE':
      return { ...state, titlePage: payload }
    case 'VALIDACAO':
      return { ...state, erro: payload }
      
    case 'INFO_FILTERED':
      return { ...state, filters: payload}
    case 'INFO_FILTERS':
      return { ...state, filters: {...state.filters, [payload.name]: payload.value}}
    case 'INFO_FILTERS_HAS':
      return { ...state, filters: {...state.filters, ...Object.keys(state.filters[payload.name]).length === 0 ? {[payload.name]: payload.value}:{}}}
    case 'INFO_FILTERS_ACTIVE':
      return { ...state, filterActive: payload}
    case 'INFO_FILTERS_CLEAN':
      return { ...state, filters: initialStateFilters, filterActive: false}
    default:
      return state
  }
}