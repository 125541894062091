import { Loading, adicionaZero } from "../components"
import domtoimage from "../components/domToImage/dom-to-image"
import { nomesMeses } from "../layout/redux/layoutReducer"

export const paramsApi = e => {
  let params = ``
  if (e) {
    params = `?`
    Object.keys(e).map((par)=> {
      params = `${params === '?' ? params : params + '&'}${par}=${e[par]}`
    })
  }
  return params
}

export const numberZero = (number) => {
  return ("00" + Number(number)).slice(-2)
}

export const capitalize = (str) => {
  if (typeof str !== 'string') {
  return str;
}
return str.charAt(0).toUpperCase() + str.substr(1);
}

export const PageLoadView = ({ 
  load, 
  data, 
  children, 
  erroMessage = <p className="pageloadview-message-erro">Sem dados para exibir</p>, 
  vazioMessage = <p className="pageloadview-message-erro">Sem dados para exibir</p>, 
  height = '500px' 
}) => {
  if (load) {
    return <Loading type='element' title='' height={height} />
  } else {
    if (data === undefined) {
      return <div className="pageloadview-message">{erroMessage}</div>
    } else if (data && data.length) {
      return children
    } else {
      return <div className="pageloadview-message">{vazioMessage}</div>
    }
  }
}

export const cicloIntervalo = e => {
  const data = e.anoMesCiclo.split('-')
  const mes = nomesMeses.find(nm=> Number(nm.id) === Number(data[1]))
  return {...e, mesAno:`${mes.name}-${data[0]}`, anoMes:`${data[0]}-${data[1]}`, ano: data[0], mes: data[1]}
}

export const numberFormat = (valor, type = 2) => {
  return valor.toFixed(type)
}


export const convertChart = async ({id, titlePage}) => {
  var node = document.getElementById(id);

  try {
    const dataUrl = await domtoimage.toPng(node)
    const date = new Date()
    if (dataUrl) {
      let a = document.createElement("a");
      a.href = dataUrl;
      a.download = `grafico-${titlePage.replace(' ', '_')}_${numberZero(date.getDay())}-${numberZero(date.getMonth())}-${date.getFullYear()}_${date.getHours()}${date.getMinutes()}.png`;
      a.click();
    }
  } catch (error) {
    console.error('oops, something went wrong!', error);
  }
  return false
}

export const numberAleatori = (valor=99999999999999) => {
  return Math.floor(Math.random() * valor)
}

export const graficoCustom = (e, p, t) => {
  console.log(e, 'graficoCustom');
  let dataProjeto = e.data.map(data => {
    let itensProjeto = {}
    data.item.map(item => {
      if (t) {
        const itemlabels = e.labels.find(il=> il.type === item.chave)
        // console.warn(itemlabels, 'itemlabelsitemlabelsitemlabelsitemlabelsitemlabels');
        itensProjeto = { 
          ...itensProjeto, 
          [item.chave]: Number(item.valor),
          ... item.chave.includes('Atual')? {Atual: Number(item.valor), colorAtual: itemlabels.color, key: item.chave.replace('Atual', '')}:{}, 
          ... item.chave.includes('Anterior')? {Anterior: Number(item.valor), colorAnterior: itemlabels.color, key: item.chave.replace('Anterior', '')}:{},
        }
      } else {
        itensProjeto = { ...itensProjeto, [item.chave]: Number(item.valor) }
      }
    })
    return {
      ...data,
      ...itensProjeto,
    }
  })

  let analiseProjeto = ''
  if (e.analise && e.analise.length) {
    e.analise.map(ap=> {
        analiseProjeto = `${analiseProjeto}<p><strong>${ap.topico}</strong> ${ap.texto}</p>`
    })
  }
  const grafico = {
    data: dataProjeto,
    labels: e.labels,
    analiseGrafica: analiseProjeto,
    
    ...e.media?{media:e.media}:{},
    ...e.desvio?{desvio:e.desvio}:{},
  }

  return grafico
}

export const BarCustom = (props) => {
  const { fill, x, y, width, height } = props;
  if (height < 0) {
    const heightCustom = Math.abs(height) 
    return <rect x={x} y={y - heightCustom} width={width} height={Number(heightCustom)}  rx="6" fill={props[`color${fill}`]} />
  } else {
    return <rect x={x} y={y} width={width} height={height}  rx="6" fill={props[`color${fill}`]} />
  }
};

export const CustomTooltipCustom = ({ active, payload, label, labels }) => {
  return (active && payload && payload.length ? (
  <div className="custom-tooltip">
    <p className="label">{`${label}`}</p>
    {payload.map((infopay, i)=> {
      const {name, value, color} = infopay
      const labelItem = labels.filter(f=> f.type === `${name}${infopay.payload.key}`)
      return (
      <p className="desc"key={`tooltipcustom-${name}-${i}`}>
        <span style={{backgroundColor: infopay.payload[`color${name}`]}}></span> 
        {`${labelItem.length && labelItem[0].name} : ${value}${'%'}`}
      </p>
    )})}
  </div>
):  null)}

export const RenderLegendCustom = ({ payload, labels }) => {
  return (
    <div className={`custom-legend legend-right`}>
      {labels.map((label, i) => {
        return (
        <div key={`item-${i}`}>
          <span style={{backgroundColor: label.color}}></span> 
          {label.name}
        </div>
      )})}
    </div>
  )
}